<template>
  <v-card :min-width="370" class="item-details-card">
    <v-card-text class="py-3 px-5">
      <div class="d-flex align-center mb-3 mt-2">
        <img
          :src="profileImage"
          alt=""
          class="item-details-card__avatar mr-4"
          @click="$emit('open-details')"
        >
        <div>
          <div
            class="item-details-card__name pb-1"
            @click="$emit('open-details')"
          >
            {{ title }}
          </div>
          <div class="item-details-card__subtitle">
            {{ subtitle }}
          </div>
        </div>
      </div>

      <v-row class="px-2 pt-1">
        <v-col cols="4" class="d-flex align-start justify-center pa-1">
          <v-icon size="22" color="#eba42b" class="mr-2 pt-1">
            mdi-star
          </v-icon>
          <div class="d-flex flex-column">
            <div class="metric-score metric-score--gold">
              {{ leadScore }}
            </div>
            <div class="point-text point-text--regular">
              Lead Score
            </div>
          </div>
        </v-col>
        <v-col cols="4" class="d-flex align-start justify-center pa-1">
          <div class="mr-2">
            <ProgressCircle :value="Number.isInteger(openRate) ? openRate : openRate.toFixed(1)" />
          </div>
          <div class="d-flex flex-column">
            <div class="metric-score">
              {{ Number.isInteger(openRate) ? openRate : openRate.toFixed(1) }}%
            </div>
            <div class="point-text point-text--regular">
              Open Rate
            </div>
          </div>
        </v-col>
        <v-col cols="4" class="d-flex align-start justify-center pa-1">
          <div class="mr-2">
            <ProgressCircle :value="Number.isInteger(clickRate) ? clickRate : clickRate.toFixed(1)" />
          </div>
          <div class="d-flex flex-column">
            <div class="metric-score">
              {{ Number.isInteger(clickRate) ? clickRate : clickRate.toFixed(1) }}%
            </div>
            <div class="point-text point-text--regular">
              Click Rate
            </div>
          </div>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import ProgressCircle from "@/sharedComponents/ProgressCircle";

export default {
  name: "ItemDetailsPopup",
  components: {
    ProgressCircle,
  },
  props: {
    title: {
      default: () => "",
      type: String,
    },
    subtitle: {
      default: () => '-',
      type: String,
    },
    id: {
      default: () => {},
      type: Number,
    },
    contact: {
      default: () => ({})
    }
  },
  data() {
    return {
      contact_statistics: []
    };
  },
  computed: {
    openRate() {
      return this.contact_statistics?.contactStatistics?.uniqueOpenRate ?? 0;
    },
    clickRate() {
      return this.contact_statistics?.contactStatistics?.uniqueClickRate ?? 0;
    },
    leadScore() {
      return this.contact_statistics?.contactStatistics?.leadScore ?? 0;
    },
    profileImage() {
      let contact = this.contact;
      if (!contact && this.id) {
        contact = this.$rest.contact.get_contact(this.id);
      }
      if (contact.pictureUrl) {
        return contact.pictureUrl;
      }
      if (contact?.displayContactEmail) {
        return contact.displayContactEmail.gravatarUrl + "?d=mp";
      }

      return 'https://www.sideralsoft.com/wp-content/uploads/2019/03/user-placeholder.png';
    },
  },
  async created() {
    await this.get_contact_statistics(this.id);
  },
  methods: {
    async get_contact_statistics(id) {
      this.contact_statistics = (
        await this.$rest.contact.get_contact_statistics(id)
      ).data;
    },
  },
};
</script>

<style lang="scss" scoped>
.item-details-card {
  &__title {
    font-family: "Open Sans", sans-serif;
    font-size: 13px !important;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #241c15;
  }
  &__avatar {
    height: 80px;
    width: 80px;
    border-radius: 50%;
    object-fit: cover;
    cursor: pointer;
  }
  &__name {
    font-family: "Open Sans", sans-serif;
    font-size: 20px;
    font-weight: 600;
    color: #241c15;
    cursor: pointer;
  }
  &__subtitle {
    font-family: "Open Sans", sans-serif;
    font-size: 12px;
    color: #66788e;
  }

  .metric-score {
    font-family: "Open Sans", sans-serif;
    font-size: 20px;
    font-weight: 600;
    line-height: normal;
    color: #2b84eb;
    &--gold {
      color: #eba42b;
    }
  }
}
</style>
