<template>
  <div class="search-table">
    <v-simple-table>
      <thead>
        <tr class="search-table__header">
          <th>Contact</th>
          <th>Website</th>
          <th>Title</th>
          <th>Action Name</th>
          <th>Engagement Time</th>
          <th>Action Source</th>
          <th>Date Time</th>
        </tr>
      </thead>
      <tbody class="search-table__content">
        <tr v-for="(item, index) in records" :key="index">
          <td class="search-table__id" style="text-align: left;display: flex;">
            <div>
              <v-menu :close-on-content-click="false" open-on-hover>
                <template #activator="{ on, attrs }">
                  <span class="id-icon">
                    <v-btn color="#2b84eb" icon small v-bind="attrs" v-on="on">
                      <v-icon color="#2b84eb" size="18">$eye</v-icon>
                    </v-btn>
                  </span>
                </template>

                <item-details-popup
                  :id="item.webTrackingAction.contact.id"
                  :key="item.webTrackingAction.contact.id"
                  :title="`${item.webTrackingAction.contact.firstName} ${item.webTrackingAction.contact.lastName}`"
                  @open-details="$router.push({name: 'ContactDetails', params: {id: item.webTrackingAction.contact.id}})"
                />
              </v-menu>
            </div>

            <div>
              <a class="fake-link" @click="goToContactPage(item)">
                <span v-if="item.webTrackingAction.contact.firstName || item.webTrackingAction.contact.lastName">
                  {{ item.webTrackingAction.contact.firstName }} {{ item.webTrackingAction.contact.lastName }}
                </span>
                <span v-else>
                  N/A
                </span>
                <br>
                <small class="text--disabled" style="font-size: 11px;">ID: {{ item.webTrackingAction.contact.id }}</small>
              </a>
            </div>
          </td>
          <td
            class="search-table__creative"
            @click="goToSearchWeb(item.creativeName)"
          >
            {{ item.creativeName }}
          </td>
          <td class="ellipsis">
            <a
              class="download-link"
              target="_blank"
              :href="item.uri"
            >
              <span v-if="item.title">
                {{ item.title }}
              </span>
              <span v-else>
                {{ item.uri }}
              </span>
            </a>
          </td>
          <td>
            {{ getActivityTypeName(item.type) }}
          </td>
          <td>
            {{ item.engagementTime }} seconds
          </td>
          <td>
            {{ item.webTrackingAction.source.name || '-' }}
          </td>
          <td>
            {{ getDateWithTime(item.activityDate) }}
          </td>
        </tr>
        <tr v-if="!records.length">
          <td colspan="8">
            No record found.
          </td>
        </tr>
      </tbody>
    </v-simple-table>
  </div>
</template>

<script>
import ItemDetailsPopup from "@/views/Contacts/components/ItemDetailsPopup";
import datesMixin from "@/utils/datesMixin.js";

export default {
  name: "ActionWebTable",
  components: { ItemDetailsPopup },
  mixins: [datesMixin],
  props: {
    records: {
      type: Array,
      required: true,
    },
  },
  methods: {
    goToContactPage(record) {
      this.$router.push({
        name: "ContactDetails",
        params: {
          id: record.contact.id,
        },
      });
    },
    goToSearchWeb(websiteName) {
      this.$router.push({
        name: "WebTrackingScripts",
        query: {
          keyword: websiteName,
        },
      });
    },
    getActivityTypeName(type) {
      switch(type) {
        case "60":
          return 'Webpage Visit';
        case "63":
          return 'Media View';
        case "64":
          return 'Download file';
      }

      return 'Unknown'
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/contact-info-table.scss";
.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
}
</style>

